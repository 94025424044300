export const formStructure = {
  name: {
    initialValue: "",
    label: "Name",
    placeholder: "Name",
    type: "text",
    width: 12,
  },
  description: {
    initialValue: "",
    label: "Description",
    placeholder: "Description",
    type: "text",
    width: 12,
  },
};
